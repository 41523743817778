import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Input,
  Button,
  InputAdornment,
  Divider
} from "@mui/material";
import { eyeSvg, crossEyeSvg, headerLogo, checkIcon } from "./assets";
import { Formik, Field, Form, ErrorMessage } from 'formik';
// Customizable Area End

import ForgotPasswordResetController, {
  Props
} from "./ForgotPasswordResetController";
// Customizable Area Start

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: "28px",
      },
      h6: {
        fontWeight: 500,
      },

      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End


export default class ForgotPasswordReset extends ForgotPasswordResetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start
    
    const { passwordVisible, passwordVisible2 } = this.state

    // Customizable Area End

    return (
      // Customizable Area Start
      <div id="main_block">
        <ThemeProvider theme={theme}>
          {this.state.passwordResetSuccessful ? (
            <>
              <Box sx={Styles.ForgotHeader}>
              <img onClick={() => this.onPressMenuItem("Home")} src={headerLogo.default} alt="headerImage" />
              </Box>
              <Divider sx={Styles.DividerStyle} />
              <Box sx={Styles.SuccessWrapper}>
                <Box sx={Styles.checkImage}>
                  <img src={checkIcon.default} alt="Check Image" />
                </Box>
                <Typography sx={Styles.SuccessText}>
                  Your password has been <br /> successfully reset!
                </Typography>
                
                <Button
                  data-test-id="login-button"
                  sx={Styles.LoginButton}
                  onClick={() => this.onPressMenuItem("Login")}
                >
                  Login
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              <Box sx={Styles.ForgotHeader}>
              <img onClick={() => this.onPressMenuItem("Home")} src={headerLogo.default} alt="headerImage" />
              </Box>
              <Divider sx={Styles.DividerStyle} />
              <Container maxWidth="xl" sx={Styles.mainDivStyle}>
                <Typography variant="h4" gutterBottom sx={Styles.HeadingStyle}>
                  Reset your password
                </Typography>
                <Formik
                  data-test-id="formik"
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={this.state.passwordSchema}
                  onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Box sx={Styles.InputMainDivStyle}>
                        <Field
                          name="password"
                          data-test-id="password-input"
                          type={passwordVisible ? "text" : "password"}
                          as={Input}
                          sx={Styles.PhoneInput}
                          placeholder="Enter Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                data-test-id="password-img"
                                src={!passwordVisible ? eyeSvg.default : crossEyeSvg.default}
                                alt="toggle visibility"
                                style={Styles.phoneIconStyle}
                                onClick={() =>
                                  this.setState({ passwordVisible: !passwordVisible })
                                }
                              />
                            </InputAdornment>
                          }
                        />
                        <Box sx={Styles.PhoneInputError}>
                          <ErrorMessage name="password" component="" />
                        </Box>

                        <Field
                          name="confirmPassword"
                          data-test-id="confirm-password-input"
                          type={passwordVisible2 ? "text" : "password"}
                          as={Input}
                          sx={Styles.PhoneInput}
                          placeholder="Confirm Password"
                          endAdornment={
                            <InputAdornment position="end">
                              <img
                                data-test-id="confirm-password-img"
                                src={!passwordVisible2 ? eyeSvg.default : crossEyeSvg.default}
                                alt="toggle visibility"
                                style={Styles.phoneIconStyle}
                                onClick={() =>
                                  this.setState({ passwordVisible2: !passwordVisible2 })
                                }
                              />
                            </InputAdornment>
                          }
                        />
                        <Box sx={Styles.PhoneInputError}>
                          <ErrorMessage
                            data-test-id="confirm-password-error-message"
                            name="confirmPassword"
                            component=""
                          />
                        </Box>

                        <Button
                          data-test-id="password-reset-btn"
                          type="submit"
                          sx={Styles.NextButton}
                          disabled={isSubmitting}
                        >
                          Next
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Container>
            </Box>
          )}
        </ThemeProvider>
      </div>

      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Styles = {
  
  DividerStyle: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
  },
  InputMainDivStyle: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  mainDivStyle: {
    marginTop: "149.99px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "63px",
    },
  },
  
  ForgotHeader: {
    marginLeft: "226px",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
      marginTop: "25px",
      display: "flex",
      marginLeft: "0px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  PhoneInputError: {
    width:"100%",
    textAlign: "left",
    marginBottom: "46px",
    alignItems: "left !important",
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "38px",
    },
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "67px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "88px",
    },
  },
  PhoneInput: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    width: "520px",
    height: "44px",
    marginBottom: "2px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
    },
  },
  phoneIconStyle: {
    marginRight: "8px",
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  NextButton: {
    fontFamily: "Poppins",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    width: "520px",
    height: "53px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
      padding: "18px 51px",
    },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  },
  SuccessText: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "29.33px",
    textAlign: "center",
    marginBottom: "42px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "90px",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "30px",
      textAlign: "center"


    },
  },

  SuccessWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center"
  },

  checkImage: {
    textAlign: "center",
    marginTop: "87.99px",
    marginBottom: "42px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "52pxpx",
      marginBottom: "32px",

    },
  },
  LoginButton: {
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "30px",
    textAlign: "center",
    width: "520px",
    height: "53px",
    padding: "18px 51px 18px 51px",
    gap: "10px",
    borderRadius: "6px",
    background: "#1D36BB",

    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "53px",
      padding: "18px 51px",
    },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  }
}
// Customizable Area End
