import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { FormikHelpers } from 'formik';
import { setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  children?: React.ReactNode;
  // Customizable Area End
}
// Customizable Area Start
export interface ForgotPasswordApiResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      full_phone_number: string;
      activated: boolean;
      created_at: string;
      pin: number;
      valid_until: string;
    };
  };
  meta: {
    message: string;
    token: string;
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  otp: string,
  otpError: string,
  isOtpInvalid: boolean,
  timer: number,
  formikErrors: string;
  accountType: string;
  emailSchema: any;
  setSubmitting: boolean;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  phoneNumber: string;
  forgotPasswordApiResponse: ForgotPasswordApiResponse
  isVisible: boolean;
  localToken: string;
  localPhone: string;
  localOtp: string;
  isTimerRunning: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: string = "";
  requestOtpCallId: string = "";
  requestResendOtpCallId: string = "";
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  timerInterval: any;


  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleSubmit=this.handleSubmit.bind(this)
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = Yup.object().shape({
      phone: Yup.string()
        .matches(/^(0\d{9})$/, "Phone Number must be at least 10 numbers")
        .required("Phone Number is required"),
    });

     //otpSchema
     let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

     //passwordSchema
     let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      otp: '',
      localOtp:"",
      otpError: "",
      isOtpInvalid: true,
      timer: 60,
      accountType: "sms",
      setSubmitting: true,
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      localToken: "",
      localPhone: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      phoneNumber: "",
      formikErrors:"",
      forgotPasswordApiResponse: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_phone_number: "",
            activated: false,
            created_at: "",
            pin: 0,
            valid_until: ""
          }
        },
        meta: {
          message: "",
          token: ""
        },
      },
      isVisible: false,
      isTimerRunning:false
    };
    this.timerInterval = null;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    // Customizable Area End
  }
  // Customizable Area Start


  handleOtpChang = (responseJson: any) => {
    this.setState({forgotPasswordApiResponse: responseJson});
        if (responseJson?.meta?.message === "OTP Sent to Registered Mobile Number") {
          this.setState({ isVisible: true,
            localOtp: responseJson.data.attributes.pin,
            localToken: responseJson.meta.token
           })

        } else if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
          const otpError = responseJson.errors[0]?.otp || "Unknown error occurred";
          this.setState({ formikErrors: otpError });
        } else {
          this.setState({ formikErrors: "Unexpected response from server" });
        }
  }

  validateOtpChange = (responseJson:any) => {
    this.setState({
      localToken: responseJson.meta.token,
      localOtp: responseJson.data.attributes.pin
    },async ()=> await setStorageData("token", responseJson.meta.token));

    if (this.state.otp == this.state.localOtp) {
      this.hideModal();
      this.props.navigation.navigate('ForgotPasswordReset');
     
    } else {
      this.setState({ otpError: "Please enter a valid code", isOtpInvalid: true });
    }
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.requestPhoneOtpCallId) {
        this.handleOtpChang(responseJson)
      }
      else if(apiRequestCallId === this.requestOtpCallId) {
        this.validateOtpChange(responseJson)
      }
      else if(apiRequestCallId === this.requestResendOtpCallId) {
        this.startTimer()
        this.setState({
          localToken: responseJson.meta.token,
          localOtp: responseJson.data.attributes.pin
        });
      }
      else if (errorResponse) {
        const errorMessage = errorResponse.message || errorResponse.errors?.[0]?.message || "Something went wrong.";
        this.setState({ formikErrors: errorMessage });
      } else {
        this.setState({ formikErrors: "No response received from server" });
      }
      
    }
  }
    
  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }


    goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
      //change status to OTP
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATE
      this.requestEmailOtpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.passwordRecoveryStartOtpAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      this.setState({
        emailValue: values.email ? values.email : "",
      });
  
      const data = {
        type: values.accountType ? values.accountType : "email_account",
        attributes: {
          email: values.email ? values.email : "",
        },
      };
  
      const httpBody = {
        data: data,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    goToOtpAfterPhoneValidation(values: { phone: string }) {
 
      //change status to OTP

      const phoneNumberForApi = values.phone.startsWith('0') ? values.phone.slice(1) : values.phone;

      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };
      const data = {
        attributes: {
          full_phone_number: `966${phoneNumberForApi}`,
        },
      };
      const httpBody = {
        data: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATE
      this.requestPhoneOtpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.passOtpsEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    goToChangePasswordAfterOtp(values: { otpCode: string }) {
      //change status to change password
      //change status to OTP
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.requestChangePasswordCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.passwordRecoveryConfirmOtpAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      const data = {
        token: this.otpToken ? this.otpToken : "",
        otp_code: values.otpCode ? values.otpCode : "",
      };
  
      const httpBody = {
        data: data,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    goToConfirmationAfterPasswordChange(values: {
      password: any;
      confirmPassword: any;
    }) {
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.requestGoToConfirmationCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.passwordRecoveryChangePasswordAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      const data = {
        token: this.otpToken ? this.otpToken : "",
        new_password: values.password,
      };
  
      const httpBody = {
        data: data,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    goToHome() {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationHomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  
    goToLogin() {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  // Customizable Area End

  // Customizable Area Start
  async handleSubmit(values: { phone: string }, { setSubmitting }: FormikHelpers<{ phone: string }>) {
    try {
      this.goToOtpAfterPhoneValidation({
        phone: values.phone
      });
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible, otp: '', otpError: '', isOtpInvalid: false, timer:0 });
  };
  handleOtpChange = (otp: string) => {
    this.setState({ otp });
    if (otp.length > 1) { this.setState({ otpError: '',}) }
  };


  validateOtp = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token": this.state.localToken
    };
    const httpBody = {
      pin: this.state.localOtp,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmOtpApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resendOtp = () => {
    this.setState({otp: ""})
    const phoneNumberForApis = this.state.phoneNumber.startsWith('0') ? this.state.phoneNumber.slice(1) : this.state.phoneNumber;
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const httpBody = {
      data: {
      attributes: {
        full_phone_number: 966+phoneNumberForApis,
      },
    }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestResendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);



    this.setState({ isTimerRunning: true });
  };
  startTimer = () => {
    this.setState({ isTimerRunning: true, timer: 60 });

    if (!this.timerInterval) {
      this.timerInterval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.timer <= 0) {
            this.stopTimer();
            return { timer: 0 };
          }
          return { timer: prevState.timer - 1 };
        });
      }, 1000);
    }
  };

  stopTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
    this.setState({ isTimerRunning: false });
  };

  onPressMenuItems = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
 
  };
  // Customizable Area End
}
