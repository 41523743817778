import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Input,
  Button,
  InputAdornment,
  Modal,
  Divider
} from "@mui/material";
import { phoneCountrySvg, crossSvg, headerLogo } from "./assets";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import OtpInput from 'react-otp-input';
// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController";
// Customizable Area Start

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: "28px",
      },
      h6: {
        fontWeight: 500,
      },

      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End


export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start

    // Customizable Area End

    return (
      // Customizable Area Start
<div id="main_block">
  <ThemeProvider theme={theme}>
    <Box>
      <Box sx={Styles.ForgotHeader}>
        <img onClick={() => this.onPressMenuItems("Home")} src={headerLogo.default} alt="headrImage"/>
      </Box>
      <Divider sx={Styles.DividerStyle}/>
      <Container maxWidth="xl" sx={Styles.mainDivStyle}>
        <Typography
          variant="h4"
          gutterBottom
          sx={Styles.HeadingStyle}
          data-test-id="phone-number-title"
        >
          Phone Number
        </Typography>
        <Formik
        data-test-id="formik"
          initialValues={{ phone: this.state.phoneNumber || "" }}
          validationSchema={this.state.phoneSchema}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
        >
          {({ errors, touched, isSubmitting, handleChange }) => (
            <Form>
              <div>
                      <Field
                        type="tel"
                        name="phone"
                        placeholder="050 123 2345"
                        data-test-id="phone-input"
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              src={phoneCountrySvg.default}
                              alt="country code"
                              style={Styles.phoneIconStyle}
                              data-test-id="phone-country-icon"
                            />
                          </InputAdornment>
                        }
                        as={Input}
                        onChange={(e: { target: { value: any } }) => {
                          handleChange(e);
                          this.setState({ phoneNumber: e.target.value });
                        }}
                        sx={{
                          borderBottom: `2px solid ${errors.phone && touched.phone ? "#DC2626" : "#CCF1FF"
                            }`,
                          ...Styles.PhoneInput,
                        }}
                      />
                <Typography sx={Styles.PhoneInputError}>
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                    data-test-id="phone-error-message"
                  />
                </Typography>
              </div>

              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={Styles.NextButton}
                data-test-id="submit-button"
              >
                {configJSON.nextButton}
              </Button>
              {this.state.forgotPasswordApiResponse?.meta?.message ? (
                <Typography data-test-id="forgot-password-response">
                  {this.state.forgotPasswordApiResponse?.meta?.message}
                </Typography>
              ) : (
                this.state.formikErrors && (
                  <Typography data-test-id="formik-error">
                    {this.state.formikErrors}
                  </Typography>
                )
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
    <Modal
      open={this.state.isVisible}
      onClose={this.hideModal}
      aria-labelledby="modal-modal-title"
      data-test-id="modal"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Styles.modalStyle}>
        <Box sx={Styles.Wrapper}>
          <Box sx={Styles.crossWrap}>
            <Box
              data-test-id="close-modal"
              onClick={() => this.hideModal()}
              component="img"
              src={crossSvg.default}
              sx={Styles.crossImg}
              alt="Cross Button"
            />
          </Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={Styles.HeadingStyleOtp}
            data-test-id="verify-title"
          >
            Verify your Number
          </Typography>
          <Typography sx={Styles.mobileCode} data-test-id="sent-code">
            We sent a code to {this.state.phoneNumber}
          </Typography>
          <Box sx={Styles.OtpInputWrapper}>
            <OtpInput
            data-test-id="OtpInputs"
              value={this.state.otp}
              onChange={this.handleOtpChange}
              numInputs={4}
              renderInput={(props) => (
                <input
                  {...props}
                  data-test-id="otp-input"
                  style={{
                    border: this.state.otpError
                      ? "2px solid #E00307"
                      : "2px solid #D7D7D7",
                    borderRadius: "16px",
                    width: "50px",
                    height: "50px",
                    textAlign: "center",
                  }}
                />
              )}
            />
          </Box>
          {this.state.otpError && (
            <Typography
              color="error"
              sx={Styles.otpError}
              data-test-id="otp-error"
            >
              {this.state.otpError}
            </Typography>
          )}

          {this.state.isTimerRunning  ? (
            <Typography sx={Styles.OtpTimer} data-test-id="otp-timer">
              {`0:${this.state.timer}`}
            </Typography>
          ) : (
            <Typography sx={Styles.ResendCode}>
              {!this.state.otpError && `Didn't get a code?`}
              <Typography
                data-test-id="resend-otp-button"
                onClick={this.resendOtp}
                sx={Styles.ClickResendCode}
              >
                Click to resend
              </Typography>
            </Typography>
          )}
          <Button
            disabled={this.state.otp.length !== 4}
            sx={{
              ...Styles.VerifyButton,
              backgroundColor: this.state.otp.length === 4 ? "blue" : "gray",
              color: this.state.otp.length === 4 ? "white" : "darkgray",
              "&.Mui-disabled": {
                backgroundColor: "lightgray",
                color: "darkgray",
              },
            }}
            data-test-id="verify-button"
            onClick={this.validateOtp}
          >
            Verify
          </Button>
        </Box>
      </Box>
    </Modal>
  </ThemeProvider>
</div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Styles = {
   modalStyle : {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-54%, -50%)",
    bgcolor: "background.paper",
    borderRadius: '16px',
    marginLeft: '16px',
    marginRight: '16px',

},
Wrapper: {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: 'column',
  position: 'relative',
  padding: '30px',
  flexWrap: 'wrap',
  [theme.breakpoints.down("sm")]: {
      padding: '16px',
  },
},
ForgotHeader: {
marginTop: "30px",
marginLeft: "226px",
[theme.breakpoints.down("sm")]: {
  marginTop: "25px",
  marginBottom: "20px",
  marginLeft: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
},
},
DividerStyle: {
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block"
  },
},
  mainDivStyle: {
    display: "flex",
    marginTop: "150.99px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "38px",
    },
  },
  centerContent: {
    textAlign: "center",
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "67px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "88px",
    },
  },
  PhoneInput: {
    width: "520px",
    height: "44px",
    marginBottom: "2px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
    },
  },
  PhoneInputError: {
    marginBottom: "46px",
    textAlign: "left",
    color: "#DC2626",
fontFamily: "Inter",
fontSize: "12px",
fontWeight: "400",
lineHeight: "18px",

    [theme.breakpoints.down("sm")]: {
      marginBottom: "38px",
    },
  },
  phoneIconStyle: {
    marginRight: "8px",
  },
  NextButton: {
    width: "520px",
    height: "53px",
    fontSize: "20px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
      padding: "18px 51px",
    },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  },
forgotPass: {
    marginTop: '5px',
    marginBottom: '36px',
    maxWidth: "520px",
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
        height: "44px",
        marginBottom: "32px",
    },
},
LoginBtn: {
    maxWidth: "520px",
    height: "44px",
    fontSize: "20px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "44px",
        padding: "18px 51px",
    },
    "&:hover": {
        backgroundColor: "#1D36BB",
        color: "#fff",
        boxShadow: "none",
    },
},

mainModalStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
},
otpInput: {
    border: "2px solid #1D36BB",
    borderRadius: "16px",
    width: "70px",
    height: "70px",
    marginBottom: "31px",
    marginRight: "10px",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px 0px 22px",
    fontSize: "35px",
    fontWeight: "700",
    lineHeight: "29.33px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        width: "70px",
        height: "70px",
        marginRight: "10px",
        marginBottom: "0px",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
},
VerifyButton: {
    width: "520px",
    height: "53px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    fontSize: "20px",
    marginBottom: "73px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
        width: "329px",
        height: "53px",
        padding: "19.5px 133.5px",
        marginBottom: "15px",
    },
    "&:hover": {
        backgroundColor: "#1D36BB",
        color: "#fff",
        boxShadow: "none",
    },
},
HeadingStyleOtp: {
    fontWeight: 700,
    fontSize: "28px",
    marginTop: '55px',
    marginBottom: "22px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
},
crossWrap: {
    display: "flex",
    alignItems: "end",
    justifyContent: 'flex-end',
    width: "100%",
    position: "relative",
},
crossImg: {
    cursor: 'pointer'
},
mobileCode: {
    marginBottom: "37px",
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
        width: '100%'
    },
},
OtpInputWrapper: {
    display: "flex",
    justifyContent: 'center',
    marginBottom: "44px",
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down("sm")]: {
        marginBottom: "44px",
    },
    "div": {
        gap: '10px',
        justifyContent: 'center',
    }
},
OtpBoxes: {
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    backgroundColor: 'red !important',
    marginRight: '10px !important'
},
ResendCode: {
    marginBottom: "37px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
        marginBottom: "69px",
        display: "flex",
    },
},
OtpTimer: {
    color: '#2C2C2E',
    marginBottom: '37px',
    lineHeight: '29.33px',
    size: '16px',
    fontWeight: 700
},
ClickResendCode: {
    marginLeft: "5px",
    marginTop: "-4px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "29.33px",
    textAlign: "center",
    borderBottom: "1px solid black",

    [theme.breakpoints.down("sm")]: {
    },
},
otpError: {
    color: 'rgb(211, 47, 47)',
    marginBottom: '10px',
    fontSize: '14px',
},

}
// Customizable Area End
