// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfdakhliapi from "../../blocks/cfdakhliapi/src/Cfdakhliapi";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhoneNumberRegistration from "../../blocks/email-account-registration/src/PhoneNumberRegistration.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import PhoneNumberLogin from "../../blocks/email-account-login/src/PhoneNumberLogin.web";
import CreateNewPassword from "../../blocks/email-account-registration/src/CreateNewPassword.web"
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfopenbankingapi1 from "../../blocks/cfopenbankingapi1/src/Cfopenbankingapi1";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus.web";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Cfcreditscoringengine from "../../blocks/cfcreditscoringengine/src/Cfcreditscoringengine";
import Cfidentityverificationintegration from "../../blocks/cfidentityverificationintegration/src/Cfidentityverificationintegration";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ForgotPasswordReset from "../../blocks/forgot-password/src/ForgotPasswordReset.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Whatsappintegration50 from "../../blocks/whatsappintegration50/src/Whatsappintegration50";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import TermsAndConditions from "../../blocks/contentmanagement/src/ContentManagement.web"
import PrivacyPolicy from "../../blocks/contentmanagement/src/PrivacyPolicy.web"
import ShariaCompliance from "../../blocks/contentmanagement/src/ShariaCompliance.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";


const routeMap = {
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  ShariaCompliance: {
    component: ShariaCompliance,
    path: "/ShariaCompliance"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Cfdakhliapi: {
    component: Cfdakhliapi,
    path: "/Cfdakhliapi"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Signup: {
    component: PhoneNumberRegistration,
    path: "/Signup"
  },
  CreateNewPassword: {
    component: CreateNewPassword,
    path: "/CreateNewPassword"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  Login: {
    component: PhoneNumberLogin,
    path: "/Login"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Cfopenbankingapi1: {
    component: Cfopenbankingapi1,
    path: "/Cfopenbankingapi1"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  Cfcreditscoringengine: {
    component: Cfcreditscoringengine,
    path: "/Cfcreditscoringengine"
  },
  Cfidentityverificationintegration: {
    component: Cfidentityverificationintegration,
    path: "/Cfidentityverificationintegration"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  ForgotPasswordReset: {
    component: ForgotPasswordReset,
    path: "/ForgotPasswordReset"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Blockedusers: {
    component: Blockedusers,
    path: "/Blockedusers"
  },
  AddBlockeduser: {
    component: AddBlockeduser,
    path: "/AddBlockeduser"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  Whatsappintegration50: {
    component: Whatsappintegration50,
    path: "/Whatsappintegration50"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw', overflowX: "hidden" }}>
        <WebRoutesGenerator routeMap={routeMap} />
      </View>
    </BuilderProvider>
  );
}

export default App;