import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";

import {crossIcon,logo, menuIcon, crossIconBlue } from "./assets";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start
    return (
      <>
        <MainWrapper data-test-id="mainWrapper">
          <ThemeProvider theme={theme}>
            {this.state.logoutPopup &&
              <Box className="popupWrapper">
                <Box className="popupBox">
                  <Box className="subContainer">
                    <Box className="subContainerTwo">
                      <img className="crossIcon" src={crossIcon.default} onClick={this.handleCancelPopUp} />
                    </Box>
                    <Box className="subContainerThree">
                      <Typography className="popupTypo">Logout</Typography>
                      <Typography
                        className="popupTypography"
                      >
                        Are you sure you want to logout
                      </Typography>
                      <button className="logoutPopupButton" onClick={this.handleUserLogout}>Logout</button>
                      <button className="cancelLogoutButton" onClick={this.handleCancelPopUp}>Cancel</button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            {this.state.menuPopup &&
              <Box className="mainBox">
                <Box className="firstBox">
                  <Box className="secondBox">
                    <img src={logo.default}
                      className="logoImage"
                    />
                    <img src={crossIconBlue.default} className="crossImage" onClick={this.handleCancelPopupMobile} />
                  </Box>
                  <Box className="thirdBox">
                    <button
                      className="buttonName"
                    >
                      Khaled
                    </button>
                    <button
                      className="buttonLogout"
                      onClick={this.handlelogoutMobile}
                    >
                      Logout
                    </button>
                  </Box>
                  <button className="buttonLogoutSecond" onClick={this.handlelogoutMobile}>Logout</button>
                </Box>
              </Box>
            }

            <div className="mainWrapper">
              <Box className="header">
                <Box className="logoBox">
                  <img src={logo.default} className="logoImg" />
                </Box>
                <Box className="buttonBox">
                  <button className="buttonSubmitApp">Submit Your Application</button>
                  <button className="buttonNameWeb">Kahled</button>
                </Box>
                <Box className="menuBox">
                  <img src={menuIcon.default} className="menuImg" onClick={this.handleMenuPopupMobile} />
                </Box>
              </Box>
              <Box className="mainContainer">
                <Box className="containerFirst">
                  <button className="buttonMyRentals">My Rentals</button>
                  <button onClick={this.handleLogoutPopup} className="buttonLogoutWeb">Logout</button>
                </Box>
                <Box className="containerSecond">
                  <Typography className="headingTypo">My Rentals</Typography>
                  <Box className="rentalsListBox">
                    <Box className="rentalData">
                      <Typography className="rentalSAR">Your annual rentalSAR 50,000</Typography>
                      <Typography className="rentalDate">Date: 22-10-2024</Typography>
                    </Box>
                    <Box className="rentalStatus">Rejected</Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </ThemeProvider>
        </MainWrapper>
        </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({theme}) => ({
  "& .mainWrapper":{
    display:"flex",
    width:"100vw",
    // maxHeight: "100%",
    height:"100vh",
    flexDirection:"column" as "column",
  },
  "& .header":{
    display:"flex",
    flexDirection:"row",
    height:"70px",
    width:"100vw",
    background: "rgba(255, 255, 255, 1)",
    justifyContent:"space-between",
    alignItems:"center",
  },
  "& .logoBox":{
    marginLeft:"7%",
    [theme.breakpoints.down(901)]: {
      marginLeft:"2%",
      marginRight:"2%",
    }
  },
  "& .logoImg":{
    width:"100%"
  },
  "& .buttonBox":{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    gap:"16px",
    marginRight:"7%",
    [theme.breakpoints.down(901)]: {
      display:"none",
    }
  },
  " & .buttonSubmitApp": {
    background: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "1.35vw",
    fontWeight: 700,
    borderRadius: "6px",
    width: "19.33vw",
    height: "50px",
    border:"none",
    padding:"9px 5px",
    cursor:"pointer",
},
"& .buttonNameWeb":{
  background: "rgba(242, 242, 247, 1)",
  fontSize: "1.35vw",
  fontWeight: 700,
  borderRadius: "6px",
  width: "115px",
  height: "50px",
  border:"none",
  padding:"9px 5px",
  color: "rgba(29, 54, 187, 1)",
  cursor:"pointer",
},
"& .menuBox":{
  padding:"3%",
  [theme.breakpoints.up(900)]: {
    display:"none",
  }
},
"& .menuIcon":{
  width:"100%",
  cursor:"pointer",
},
  "& .mainContainer":{
    display:"flex",
    height:"calc(100vh)",
  },
  "& .containerFirst":{
    width:"20vw",
    minHeight:"100%",
    background: "rgba(255, 255, 255, 1)",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    [theme.breakpoints.down(901)]: {
      display:"none",
    }
  },
  " & .buttonMyRentals": {
    background: "rgba(255, 255, 255, 1)",
    color: "rgba(32, 34, 36, 1)",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontWeight: 600,
    width: "100%",
    height: "53px",
    padding:"9px 5px",
    border:"none",
    borderLeft:"5px solid rgb(29, 54, 187)",
    textAlign:"left",
    cursor:"pointer",
  },
  " & .buttonLogoutWeb": {
  background: "rgba(255, 255, 255, 1)",
  border: "1px solid rgba(226, 232, 240, 1)",
  color: "rgba(32, 34, 36, 1)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  width: "100%",
  height: "70px",
  padding:"9px 5px",
  cursor:"pointer",
},
  "& .containerSecond":{
    width:"80vw",
    background: "rgba(224, 224, 224, 1)",
    display:"flex",
    flexDirection:"column",
    padding:"3vw",
    [theme.breakpoints.down(901)]: {
      width:"100%",
      padding:"2vw",
    }
  },
  "& .headingTypo":{
    color: "rgba(32, 34, 36, 1)",
    fontFamily: "Poppins",
    fontSize: "2.4vw",
    fontWeight: 700,
    [theme.breakpoints.down(1300)]: {
      fontSize: "2.7vw",
     },
    [theme.breakpoints.down(901)]: {
     fontSize: "3vw",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "4vw",
     },
     [theme.breakpoints.down(450)]: {
      fontSize: "4.5vw",
     },
     [theme.breakpoints.down(400)]: {
      fontSize: "5.2vw",
     }
  },
  "& .rentalsListBox":{
    display:"flex",
    flexDirection:"row",
    background: "rgba(255, 255, 255, 1)",
    height:"calc(131px - 60px)",
    padding: "30px",
    width: "calc(100% - 60px)",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "10px",
    borderRadius: "10px",
    marginTop: "20px",
  },
  "& .rentalData":{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    width: "100%",
    gap:"1vw",
  }, 
  "& .rentalSAR":{
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontWeight: 700,
  } ,
  "& .rentalStatus":{
    width:"123px",
    height:"27px",
    borderRadius:"15px",
    background: "rgba(190, 24, 27, 1)",
    color:"rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .rentalDate":{
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .popupWrapper": {
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    position: "fixed" as "fixed",
    backgroundColor: "rgba(43, 43, 43, 0.6)",
    alignItems: "center" as "center",
    display: "flex",
    justifyContent: "center" as "center",
  },
  "& .popupBox": {
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "column" as "column",
    position: "absolute" as "absolute",
    borderRadius: "16px",
    alignItems: "center" as "center",
    padding: "1vw",
    paddingBottom: "2vw",
    margin: "1vw",
    [theme.breakpoints.down(901)]: {
      width: "60%",
      padding:"3vw"
     },
    [theme.breakpoints.down(601)]: {
      width: "80%",
      padding:"3.2vw"
     },
    [theme.breakpoints.down(450)]: {
      width: "80%",
      padding:"4vw"
     },
  },
  "& .crossIconBox": {
    width: "100%",
  },
  "& .crossIcon": {
    alignSelf: "flex-end",
    padding: "0.2vw",
    [theme.breakpoints.down(901)]: {
      width: "4vw",
      height:"4vw"
     },
    [theme.breakpoints.down(601)]: {
      width: "5vw",
      height:"5vw"
     },
    [theme.breakpoints.down(450)]: {
      width: "6vw",
      height:"6vw"
     },
  },
  "& .subContainer": {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
  },
  "& .subContainerTwo": {
    width: "100%",
    display: "flex",
    alignItems: "flex-end" as "flex-end",
    justifyContent: "end" as "end",
  },
  "& .subContainerThree": {
    width: "85%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    gap: "2vw",
    padding: "1vw 3vw 0vw 3vw",
    [theme.breakpoints.down(601)]: {
      gap: "4vw",
     },
    [theme.breakpoints.down(450)]: {
      gap: "4vw",
     },
  },
  "& .popupTypo": {
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    textAlign: "center" as "center",
  },
  "& .popupTypography": {
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    textAlign: "center" as "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.down(601)]: {
      whiteSpace:"wrap"
     },
  },
  "& .logoutPopupButton": {
    backgroundColor: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    textAlign: "center" as "center",
    borderRadius: "6px",
    width: "100%",
    padding: "0.5vw",
    border: "none",
    cursor: "pointer",
  },
  "& .cancelLogoutButton": {
    color: "rgba(29, 54, 187, 1)",
    backgroundColor: "rgba(242, 242, 247, 1)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    borderRadius: "6px",
    textAlign: "center" as "center",
    width: "100%",
    border: "none",
    padding: "0.5vw",
    cursor: "pointer",
  },
  "& .mainBox": {
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    position: "fixed" as "fixed",
    backgroundColor: "rgba(43, 43, 43, 0.6)",
    alignItems: "flex-start" as "flex-start",
    display: "flex",
    justifyContent: "center" as "center",
  },
  "& .firstBox": {
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column" as "column",
    position: "absolute" as "absolute",
    alignItems: "center" as "center",
    padding: "0vw 1vw 1vw 1vw",
    paddingBottom: "0vw",
  },
  "& .secondBox": {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center" as "center",
    width: "100%",
    border: "1px solid rgba(237, 237, 237, 1)",
  },
  "& .logoImage": {
    width: "16vw",
    height: "8vw",
    paddingLeft: "3vw",
    [theme.breakpoints.down(501)]: {
      width: "22vw",
      height: "11vw",
      paddingLeft: "4vw",     
    },
  },
  "& .crossImage": {
    width: "4vw",
    height: "4vw",
    paddingRight: "3vw",
  },
  "& .thirdBox": {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    gap: "3vw",
    padding: "2vw",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
  },
  "& .buttonName": {
    color: "rgba(29, 54, 187, 1)",
    backgroundColor: "rgba(242, 242, 247, 1)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    borderRadius: "6px",
    textAlign: "center" as "center",
    width: "95%",
    border: "none",
    padding: "1.5vw",
    cursor: "pointer",
    [theme.breakpoints.down(501)]: {
      fontSize: "15px",
    },
  },
  "& .buttonLogout": {
    backgroundColor: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    textAlign: "center" as "center",
    borderRadius: "6px",
    width: "95%",
    padding: "1.5vw",
    border: "none",
    cursor: "pointer",
    [theme.breakpoints.down(501)]: {
      fontSize: "15px",
    }
  },
  "& .buttonLogoutSecond": {
    background: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(226, 232, 240, 1)",
    color: "rgba(32, 34, 36, 1)",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    width: "100%",
    height: "70px",
    padding: "9px 20px",
    cursor: "pointer",
    textAlign: "start" as "start",
  },
}))
// Customizable Area End
