import React from "react";

// Customizable Area Start
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  CssBaseline,
  Container,
  Box,
  Grid,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { HeaderLogoo, FooterLogoo, FooterLogoo2, SmsIconn } from "./assets";

const Styles = {
  blueButton: {
   fontFamily: "Poppins",
   fontSize: "17px",
   fontWeight: "500",
   lineHeight: "25.5px",
   textAlign: "center",

    backgroundColor: "#1D36BB",
    color: "white",
    padding: "7.3px 12.9px",
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: "#303f9f",
    }
    
  },
  blueButton2: {
    backgroundColor: "#1D36BB",
    color: "white",
    fontWeight:500,
    fontSize:"17px",
    padding: "10px 18px",
    borderRadius: "4px",
    lineHeight :"25.5px",
    '&:hover': {
      backgroundColor: "#303f9f",
    }
  },
  greyButton: {
    fontFamily: "Poppins",
    backgroundColor: "#F2F2F7",
    color: "#1D36BB",
    padding: "5.2px 17.1px",
    fontWeight:500,
    fontSize:"17px",
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: "#e0e0e0",
    },
  },
  greyButton2: {
    backgroundColor: "#F2F2F7",
    marginBottom:"16px",
    color: "#1D36BB",
    padding: "10px 18px",
    fontWeight:500,
    fontSize:"17px",
    borderRadius: "4px",
    lineHeight :"25.5px",
    '&:hover': {
      backgroundColor: "#e0e0e0",
    }
  },
  appBar: {
    bgcolor: "white",
    color: "black",
    height: "81px",
    padding: { xs: "0px 16px", lg: "0px 99px", xl: "0px 226px" },
    marginTop: { xs: "0px", md: "57px" },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  footer: {
    bgcolor: "white",
    color: "black",
    padding: { xs: "27px 16px", lg: "27px 97px", xl: "27px 228px" },
    mt: 2,
    overflow: 'hidden',
    '@media (max-width:600px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  footerGridItemLeft: {
    display: 'flex'
  },
  footerGridItemRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mobileFooter: {
    display: { xs: 'flex', md: 'none' },
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: "#F7F7F7",
    borderTop: "1px solid #D8D8D8",
    color: "black",
    padding:"24px 24px 122px",
  },
  mobileFooterContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  mobileFooterLeftAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  mobileFooterCenterAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: "91px",
  },
  mobileFooterText: {
    marginTop: '20px',
    fontSize: '17px',
    fontWeight: 400,
  },
  mobileHeader:{
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    textAlign: "left",
    
  },
  mobileFooterTextPara: {
    marginTop: '8px',
    fontSize: '17px',
    fontWeight: 400,
    marginBottom:"50px"
  },
  mobileFooterTextHead: {
    marginTop: '8px',
    marginBottom: "15px",
    fontSize: '17px',
    fontWeight: 700,
  },
  mobileFooterTextHead2: {
    marginTop: '44px',
    marginBottom: "8px",
    fontSize: '17px',
    fontWeight: 700,
  },
  footerLogo: {
    display: 'block',
    marginBottom:"41px"
  },
  footerLogo2: {
    marginTop: '25px',
    display: 'block',
  },
  mobileFooterCopyright: {
    marginTop: '54px',
    fontSize: '17px',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    height: '100%',
  },
  drawerList: {
    marginBottom: '16px',
  },
  drawerButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  hiddenOnMobile: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: "57px",
    backgroundColor: "#F7F7F7",
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1100,
  },
  supportContainer: {
    marginRight: { xs: "30px", lg: "100px", xl: "226px" },
    display: "flex",
    alignItems: "center",
  },
  supportIcon: {
    marginRight: "12.33px",
    marginBottom: "-4px",
    width: "21.33px",
    height: "14.93px",
  },
  supportMailBtn: {
    fontFamily: "Poppins",
fontSize: "17.73px",
fontWeight: "400",
lineHeight: "29.33px",
textAlign: "right",
  },
  DesktopAppMail: {
    fontFamily: "Poppins",
fontSize: "17.73px",
fontWeight: "400",
lineHeight: "29.33px",
textAlign: "right",
  },
  languageChangeText:{
    marginRight: "28.33px",
    fontFamily: "Poppins",
    fontSize: "20.73px",
    fontWeight: "700",
    lineHeight: "29.33px",
    textAlign: "right"
    
  },
  iconButton: {
    color: "#1D36BB",
    cursor: "pointer",
  },
  arabTextStyle:{
    fontWeight:700,
    fontSize:"20px"
  },
  HeaderTextStyle: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    textAlign: "left",   
  },
  ActiveNavbar:{
    fontFamily: "Poppins",
fontSize: "18px",
fontWeight: "700",
lineHeight: "27px",
textAlign: "left",
    borderBottom: "3px solid #1D36BB",
    borderRadius: "0px",
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
    padding: "0px"
  },
  HomeBtn:{
    marginLeft: "73.38px",  
    marginRight: "42.66px"  
  },
  rtl: {
    direction: 'rtl',
  },
  ltr: {
    direction: 'ltr',
  },
  HoverStyle: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
  }
}
};
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { webDrawer, isMobile, selectedLanguage, ActivePathnameState } = this.state;
    const directionStyle = selectedLanguage === 'Arabic' ? Styles.rtl : Styles.ltr;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={Styles.hiddenOnMobile}>
          <Box className="dropdownMenu">
            {selectedLanguage == "English" ?
              <Typography sx={{ ...Styles.languageChangeText, ...Styles.HoverStyle }} data-test-id="arabicLang1" className="dropdownItem" onClick={() => this.languageChange("Arabic")}>عربي</Typography>

              :
              <Typography sx={{ ...Styles.languageChangeText, ...Styles.HoverStyle }} data-test-id="englishLang2" className="dropdownItem" onClick={() => this.languageChange("English")}>English</Typography>
            }
          </Box>
          <Box sx={Styles.supportContainer}>
            <Typography
              sx={{
                ...Styles.DesktopAppMail, ...Styles.HoverStyle,
                textDecoration: "none",
                color: "inherit",
              }}
              component="a"
              href="mailto:support@aqsat.com"
            >
              <img src={SmsIconn.default} alt="sms icon" style={Styles.supportIcon} />
              {configJSON.supportMail}
            </Typography>
          </Box>
        </Box>
        <AppBar position="fixed" sx={Styles.appBar}>
          <Toolbar disableGutters sx={{ ...Styles.toolbar }}>
            {isMobile ? (
              <>
                <Typography
                  data-test-id="headerLogo2"
                  variant="h6"
                  sx={{ flexGrow: 1, ...Styles.HoverStyle }}
                  onClick={() => this.onPressMenuItem("Home")}
                >
                  <img style={{ width: "92.14px", height: "34px" }} src={HeaderLogoo.default} alt="Header Logo" />
                </Typography>
                
                <IconButton
                  edge="end"
                  sx={Styles.iconButton}
                  color="inherit"
                  aria-label="menu"
                  data-test-id="btnToggleDraw"
                  onClick={this.toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Typography
                  variant="h6"
                  sx={{ display: 'flex', alignItems: 'center', ...Styles.HoverStyle }}
                  onClick={() => this.onPressMenuItem("Home")}
                >
                  <img src={HeaderLogoo.default} alt="Header Logo" />
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                  <Button
                    sx={{
                      ...(ActivePathnameState == "/LandingPage" || ActivePathnameState === "/" ? Styles.ActiveNavbar : Styles.HeaderTextStyle), ...Styles.HomeBtn,
                      textTransform: 'capitalize'
                    }}
                    color="inherit"
                    onClick={() => this.onPressMenuItem("Home")}
                    data-test-id="btnHome"
                  >
                    Home
                  </Button>

                  <Button
                    sx={{
                      ...(ActivePathnameState == "/Contactus" ? Styles.ActiveNavbar : Styles.HeaderTextStyle),
                      textTransform: 'capitalize'
                    }}
                    color="inherit"
                    onClick={() => this.onPressMenuItem("Contactus")}
                    data-test-id="btnContactUs"
                  >
                    Contact Us
                  </Button>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Button style={{ marginRight: "15px" }}
                  sx={{ ...Styles.blueButton, textTransform: 'capitalize' }}
                  onClick={() => this.onPressMenuItem("SignUp")}
                  data-test-id="btnSubmitApplication"
                >
                  Submit Your Application
                </Button>
                <Button
                  sx={{ ...Styles.greyButton, textTransform: 'capitalize' }}
                  onClick={() => this.onPressMenuItem("Login")}
                  data-test-id="btnLogin"
                >
                  Login
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        {this.props.children}

        {!isMobile ? (
          <>
            <Box
              style={{ backgroundColor: "#F7F7F7", border: "1px solid #D8D8D8" }}
              component="footer"
              sx={Styles.footer}
            >
              <Container maxWidth={false} sx={{ padding: "0 !important" }}>
                <Grid container >
                  <Grid item xs={12} md={7} sx={Styles.footerGridItemLeft}>
                    <Box>
                      <img
                        src={FooterLogoo.default}
                        alt="Footer Logo 1"
                        style={{ display: "block" }}
                      />
                    </Box>
                    <Box sx={{
                      textAlign: "left",
                      marginLeft: {
                        md: "82px"
                      },
                      '@media (min-width:900px) and (max-width:1040px)': {
                        marginLeft: "42px"
                      }
                    }}>
                      <Typography sx={{ fontWeight: "700" }} variant="body1">
                        AQSAT
                      </Typography>
                      <Typography sx={{ marginTop: "28px", ...Styles.HoverStyle }} variant="body1"
                        onClick={() => this.onPressMenuItem("Home")}
                        data-test-id="btnHomeMobile">
                        Home
                      </Typography>
                      <Typography sx={{ marginTop: "10px", ...Styles.HoverStyle }} variant="body1"
                        onClick={() => this.onPressMenuItem("Contactus")}
                        data-test-id="btnContactUs2">
                        Contact Us
                      </Typography>
                    </Box>
                    <Box sx={{
                      flex: 2, textAlign: "left", marginLeft: {
                        md: "87px"
                      },
                      '@media (min-width:900px) and (max-width:1040px)': {
                        marginLeft: "42px"
                      }
                    }}>
                      <Typography sx={{ fontWeight: "700" }} variant="body1">
                        Support
                      </Typography>
                      <Typography sx={{ marginTop: "27px", ...Styles.HoverStyle }} variant="body1" data-test-id="privacyPolicy1" onClick={() => this.onPressMenuItem("PrivacyPolicy")}>
                        Privacy Policy
                      </Typography>
                      <Typography sx={{ marginTop: "10px", ...Styles.HoverStyle }} variant="body1"
                        data-test-id="tAndC1" onClick={() => this.onPressMenuItem("TermsAndConditions")}>
                        Terms & Conditions
                      </Typography>
                      <Typography sx={{ marginTop: "10px", marginBottom: "6px", ...Styles.HoverStyle }} variant="body1"
                        data-test-id="sharia1" onClick={() => this.onPressMenuItem("ShariaCompliance")}>
                        Sharia Review
                      </Typography>
                      <Typography variant="body1"
                        sx={{
                          ...Styles.supportMailBtn,
                          textDecoration: "none",
                          color: "inherit",
                          ...Styles.HoverStyle
                        }}
                        component="a"
                        href="mailto:support@aqsat.com"
                      >
                        {configJSON.supportMail}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5} sx={Styles.footerGridItemRight}>
                    <Box sx={{ textAlign: "right", ml: { md: "auto" }, mr: { md: 3 } }}>
                      <Typography variant="body1">Licensed by</Typography>
                      <Typography variant="body1">
                        License Number: 1200028177
                      </Typography>
                      <img
                        style={{ marginTop: "13px" }}
                        src={FooterLogoo2.default}
                        alt="Footer Logo 2"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ mt: 2, marginTop: "84px", fontSize: "17px" }}
                >
                  AQSAT © 2024
                </Typography>
              </Container>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ ...Styles.mobileFooter, ...directionStyle }}>
              <Box sx={Styles.mobileFooterContent}>
                <Box sx={Styles.mobileFooterLeftAlign}>
                  <Typography
                    variant="body1"
                    sx={Styles.mobileFooterTextHead}
                  >
                    AQSAT
                  </Typography>
                  <Typography variant="body1" sx={Styles.mobileFooterText} onClick={() => this.onPressMenuItem("Home")}>
                    Home
                  </Typography>
                  <Typography data-test-id="contactUs1" variant="body1" sx={Styles.mobileFooterText} onClick={() => this.onPressMenuItem("Contactus")}>
                    Contact Us
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={Styles.mobileFooterTextHead2}
                  >
                    Support
                  </Typography>
                  <Typography data-test-id="privacyPolicy1" variant="body1" sx={Styles.mobileFooterText} onClick={() => this.onPressMenuItem("PrivacyPolicy")}>
                    Privacy Policy
                  </Typography>
                  <Typography data-test-id="tAndC1" variant="body1" sx={Styles.mobileFooterText} onClick={() => this.onPressMenuItem("TermsAndConditions")}>
                    Terms & Conditions
                  </Typography>
                  <Typography data-test-id="sharia1" variant="body1" sx={Styles.mobileFooterText} onClick={() => this.onPressMenuItem("ShariaCompliance")}>
                    Sharia Review
                  </Typography>
                  <Typography variant="body1"
                    sx={{
                      ...Styles.supportMailBtn,
                      textDecoration: "none",
                      color: "inherit",
                      marginTop: "8px",
                      "&:hover": {
                        textDecoration: "none"
                      }
                    }}
                    component="a"
                    href="mailto:support@aqsat.com"
                  >
                    {configJSON.supportMail}
                  </Typography>
                </Box>
                <Box sx={Styles.mobileFooterCenterAlign}>
                  <img
                    src={FooterLogoo.default}
                    alt="Footer Logo 1"
                    style={Styles.footerLogo}
                  />
                  <Typography variant="body1" sx={Styles.mobileFooterText}>
                    Licensed by
                    <br />
                    License Number: 1200028177
                  </Typography>
                  <img
                    src={FooterLogoo2.default}
                    alt="Footer Logo 2"
                    style={Styles.footerLogo2}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}


        <Drawer
          anchor={isMobile ? "top" : "left"}
          open={webDrawer}
          data-test-id={"btnToggleDraw"}
          onClose={this.toggleDrawer}
        >
          {isMobile ? (
            <Box sx={Styles.drawerContent}>
              <Toolbar disableGutters>
                <Typography data-test-id="headerLogo1" variant="h6" sx={{ flexGrow: 1 }} onClick={() => this.onPressMenuItem("Home")}>
                  <img style={{ width: "92.14px", height: "34px" }} src={HeaderLogoo.default} alt="Header Logo" />
                </Typography>
                <IconButton
                  sx={Styles.iconButton}
                  edge="end"
                  color="inherit"
                  aria-label="close"
                  data-test-id="btnToggleDraw"
                  onClick={this.toggleDrawer}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              <Box sx={{ ...Styles.drawerList, ...directionStyle }}>
                <List>
                  <ListItem disableGutters button style={{ marginTop: "29px", marginBottom: "-8px" }}
                    onClick={() => this.onPressMenuItem("Home")}
                    data-test-id="btnHomeMobile">
                    <Typography variant="body1" sx={Styles.mobileHeader}>
                      Home
                    </Typography>
                  </ListItem>
                  <ListItem disableGutters button onClick={() => this.onPressMenuItem("Contactus")} data-test-id="btnContactUs">
                    <Typography variant="body1" sx={Styles.mobileHeader}>
                      Contact Us
                    </Typography>
                  </ListItem>
                  <Divider style={{ marginTop: "15px", marginBottom: "12px" }} />
                  <ListItem disableGutters data-test-id="btnSupport">
                    <Typography
                      sx={{
                        ...Styles.supportMailBtn,
                        textDecoration: "none",
                        color: "inherit",
                        "&:hover": {
                          textDecoration: "none"
                        }
                      }}
                      component="a"
                      href="mailto:support@aqsat.com"
                    >
                      {configJSON.supportMail}
                    </Typography>
                  </ListItem>
                  <ListItem sx={Styles.arabTextStyle} data-test-id="btnArabic"
                  >
                    {selectedLanguage == "English" ?
                      <Typography sx={Styles.languageChangeText} data-test-id="arabicLang2" className="dropdownItem" onClick={() => this.languageChange("Arabic")}>عربي</Typography>

                      :
                      <Typography sx={Styles.languageChangeText} data-test-id="englishLang1" className="dropdownItem" onClick={() => this.languageChange("English")}>English</Typography>
                    }
                  </ListItem>
                </List>
              </Box>
              <Box sx={Styles.drawerButtonContainer}>
                <Button sx={{ ...Styles.greyButton2, textTransform: 'capitalize' }} onClick={() => this.onPressMenuItem("Login")} data-test-id="btnLogin">
                  Login
                </Button>
                <Button sx={{ ...Styles.blueButton2, textTransform: 'capitalize' }} onClick={() => this.onPressMenuItem("SignUp")} data-test-id="btnSubmitApplication">
                  Submit Your Application
                </Button>
              </Box>
            </Box>

          ) : null}
        </Drawer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Poppins',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '27px',
      textAlign: 'left',
    },
  },
});
// Customizable Area End
